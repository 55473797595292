<template>
  <div class="index-wrapper add-fixed index-wrapper-dialog">
    <div class="screen-wrapper">
      <img class="bg" src="@/assets/image/bg.jpg" />
      <dv-loading class="loading">
        <img class="bg" src="@/assets/image/bg.jpg" />
        <div @dblclick="onOutPageFn" class="loading-text">欢迎来到AI模拟面试</div>
        <div class="start-btn">
          <img
            class="bg"
            @click="onSkipPageFn('/interview/be_being_interview', 0)"
            src="@/assets/image/start-btn1.png"
          />
          <img class="bg" @click="getLoginQrcodeFn" src="@/assets/image/start-btn2.png" />
        </div>
      </dv-loading>
    </div>
    <!--游客报告-->
    <div
      v-if="this.channel"
      @click="onSkipReportPageFn('/interview/tourist_report')"
      class="report-btn-fixed"
    >
      <img src="@/assets/image/drawer-box-icon2.png" />
      <span>游客报告</span>
    </div>
    <!--扫码报告-->
    <div
      v-if="this.channel"
      @click="onSkipReportPageFn('/interview/report_list')"
      class="report-btn-fixed report-btn-fixed2"
    >
      <img src="@/assets/image/drawer-box-icon2.png" />
      <span>扫码报告</span>
    </div>
    <!--扫码登录弹窗-->
    <el-dialog
      :visible.sync="popUpsState"
      :modal-append-to-body="false"
      :show-close="false"
      :center="true"
      :modal="true"
      @closed="onClosedFn"
      width="392px"
      top="0"
    >
      <div class="pop-up-bg">
        <img src="@/assets/image/qr-pop-up.png" />
      </div>
      <div v-if="qrStatusObj" class="pop-up-wrapper">
        <div class="title">打开微信扫描二维码登录</div>
        <div v-if="qrStatusObj.is_expired === 1 || qrStatusObj.is_scan === 0" class="qr-box">
          <div class="qr-img">
            <img :src="loginObj.qrcode" />
          </div>
          <div v-if="qrStatusObj.is_expired === 1" @click="getLoginQrcodeFn" class="qr-fixed">
            <div class="text">二维码已失效</div>
            <div class="btn-box">
              <div class="btn">
                <img src="@/assets/image/refresh-icon.png" />
                <span>点击刷新</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="qrStatusObj.is_expired === 1 || qrStatusObj.is_scan === 0" class="bottom-title">
          面试后可在小程序上查看报告
        </div>
        <div v-if="qrStatusObj.is_expired === 0 && qrStatusObj.is_scan === 1" class="success-box">
          <img src="@/assets/image/success-icon.png" />
          <div class="success-text">扫码成功</div>
          <div class="text">请在手机上确认 [ 授权登录 ]</div>
        </div>
      </div>
    </el-dialog>

    <ExitApplication v-if="popUp" @ensureFn="ensureFn" @cancelfn="cancelfn" />
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { upyunUrl } from '@/api/config'
import ExitApplication from '@/components/ExitApplication.vue'
let timer = null

export default {
  components: {
    ExitApplication //退出应用组件
  },
  data() {
    return {
      popUp: false, //控制退出应用弹窗的显示
      upyunUrl,
      channel: '', // 机器ID-渠道ID
      popUpsState: false,
      loginObj: null, // 登录二维码信息
      qrStatusObj: null // 二维码情况信息
    }
  },
  created() {
    const _this = this
    const isAnswerCompleted = localStorage.getItem('isAnswerCompleted')
    if (isAnswerCompleted) {
      localStorage.removeItem('isAnswerCompleted')
      location.reload()
    }
    this.channel = localStorage.getItem('channel')
    if (this.$route.query.channel) {
      this.channel = this.$route.query.channel
      localStorage.setItem('channel', this.channel)
      this.setChannel(this.channel)
    } else if (this.channel) {
      this.setChannel(this.channel)
    } else {
      this.$message({
        message: '参数错误',
        type: 'error'
      })
      return false
    }
    this.getLoginChannelVerifyFn()
    document.onkeydown = function (e) {
      if (e.keyCode === 13 || e.keyCode === 38 || e.keyCode === 40)
        _this.onSkipPageFn('/interview/be_being_interview', 0)
      if (e.keyCode === 27) _this.onOutPageFn()
    }
  },
  methods: {
    ...mapMutations({
      setChannel: 'SET_CHANNEL',
      setInterviewToken: 'SET_INTERVIEWTOKEN'
    }),
    ...mapActions(['getLoginChannelVerify', 'getLoginQrcode', 'getLoginScanInfo']),
    // 获取机器-渠道校验参数
    async getLoginChannelVerifyFn() {
      const data = {
        channel: this.channel
      }
      const obj = await this.getLoginChannelVerify(data)
      if (obj.data.verify === 0) {
        this.channel = ''
        this.$message({
          message: obj.data.msg,
          type: 'error'
        })
      }
    },
    // 监听打开退出弹窗
    onOutPageFn() {
      // this.$confirm('是否退出应用?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      //   center: true,
      //   closeOnPressEscape: false
      // }).then(() => {
      //   history.go(-history.length + 1)
      // })
      this.popUp = true
    },
    //当用户点击确定退出
    ensureFn() {
      history.go(-history.length + 1)
    },
    //当用户点击取消关闭退出应用的弹窗
    cancelfn(data) {
      this.popUp = data
    },
    // 获取登录二维码
    async getLoginQrcodeFn() {
      if (!this.channel) {
        this.$message({
          message: '参数错误',
          type: 'error'
        })
        return false
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.loginObj = await this.getLoginQrcode({ channel: this.channel })
      this.getLoginScanInfoFn()
      this.popUpsState = true
      loading.close()
    },
    // 获取二维码状态信息
    async getLoginScanInfoFn() {
      const data = {
        scene: this.loginObj.scene,
        channel: this.channel
      }
      this.qrStatusObj = await this.getLoginScanInfo(data)
      if (this.qrStatusObj.is_expired === 1) {
        clearInterval(timer)
        return false
      } else if (this.qrStatusObj.is_scan === 1 && this.qrStatusObj.token) {
        // 跳转选择面试岗位
        this.setInterviewToken(this.qrStatusObj.token)
        sessionStorage.setItem('interviewToken', this.qrStatusObj.token)
        this.popUpsState = false
        setTimeout(() => {
          this.onSkipPageFn('/interview/be_being_interview', 1)
        }, 1000)
        return false
      } else if (this.qrStatusObj.is_expired === 3) {
        this.popUpsState = false
      }
      this.timingStart()
    },
    // 开始计时
    timingStart() {
      clearInterval(timer)
      timer = setInterval(() => {
        this.getLoginScanInfoFn()
      }, 1000)
    },
    // 登录弹窗关闭后
    onClosedFn() {
      this.loginObj = null
      this.qrStatusObj = null
      clearInterval(timer)
    },
    // 跳转页面
    onSkipPageFn(url, id) {
      if (!this.channel) {
        this.$message({
          message: '参数错误',
          type: 'error'
        })
        return false
      }
      this.$router.push({
        query: {
          id
        },
        path: url
      })
    },
    // 跳转报告列表
    onSkipReportPageFn(url) {
      this.$router.push(`${url}?channel=${this.channel}`)
    }
  }
}
</script>

<style lang="stylus" scoped>
.index-wrapper, position fixed
  left 0
  top 0
  width 100%
  height 100%
  z-index 99
  &.add-fixed
    position fixed
  .screen-wrapper
    position relative
    width 100%
    height 100%
    padding 36px 50px 30px
    box-sizing border-box
.bg
  position absolute
  left 0
  top 0
  bottom 0
  z-index -1
  width 100%
  height 100%
.loading
  position fixed
  left 0
  top 0
  width 100%
  height 100%
  display flex
  align-items center
  justify-content center
  z-index 999
  background-color #000
  .loading-text
    font-weight bold
    line-height 100px
    font-size 30px
    text-align center
    opacity 0.8
    color #fff
  .start-btn
    width 100%
    height auto
    display flex
    img
      position relative
      width 200px
      height auto
      display block
      cursor pointer
      margin 0 30px
      z-index 10
.report-btn-fixed
  position fixed
  right 20px
  top 43%
  z-index 999
  cursor pointer
  img
    width 54px
    height 54px
  span
    display block
    width 100%
    height auto
    padding-top 4px
    line-height 14px
    text-align center
    font-size 12px
    color #fff
.report-btn-fixed2
  top 55%
.pop-up-bg
  position absolute
  left 0
  top 0
  width 100%
  height 392px
  z-index -1
  img
    display block
    width 100%
    height 100%
.pop-up-wrapper
  width 100%
  height 310px
  .title
    width 100%
    height auto
    padding-top 20px
    font-size 18px
    font-weight bold
    text-align center
    line-height 20px
    color #fff
  .qr-box
    position relative
    width 170px
    height 170px
    padding 12px
    margin 20px auto 0 auto
    border-radius 4px
    background-color #fff
    box-sizing border-box
    .qr-img
      width 100%
      height 100%
      img
        display block
        width 100%
        height 100%
    .qr-fixed
      position absolute
      left 0
      top 0
      width 100%
      height 100%
      z-index 10
      cursor pointer
      background-color rgba(0, 0, 0, 0.6)
      .text
        width 100%
        height auto
        padding-top 55px
        text-align center
        font-size 18px
        line-height 18px
        font-weight bold
        color #fff
      .btn-box
        width 100%
        height auto
        padding-top 14px
        display flex
        justify-content center
        .btn
          width auto
          height auto
          padding 0 12px
          border-radius 4px
          line-height 34px
          display flex
          align-items center
          background-color #fff
          img
            display block
            width 16px
            height 16px
          span
            padding-left 4px
            font-size 16px
            color #333
  .bottom-title
    width 100%
    height auto
    padding-top 20px
    font-size 14px
    text-align center
    color #13B7C3
  .success-box
    width 100%
    height auto
    padding-top 33px
    img
      display block
      width 65px
      height 65px
      margin 0 auto
    .success-text
      width 100%
      height auto
      padding-top 23px
      text-align center
      line-height 24px
      font-size 24px
      font-weight bold
      color #00bb4b
    .text
      width 100%
      height auto
      padding-top 18px
      text-align center
      font-size 18px
      line-height 18px
      color #fff
</style>
